<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12">
        <card-course :course="course" :edit="false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <!-- {{ course }} -->
          <form-course action="edit" :courses-prop="course" v-if="fetch" :edit="true"></form-course>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import md5 from '@/libs/md5'
import { mapMutations } from 'vuex'

export default {
  data: () => ({
    course: {},
    fetch: false
  }),
  methods: {
    ...mapMutations(['setLoading']),
    async getCourseData (organizationId) {
      try {
        this.setLoading(true)
        const classResponse = await this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}/courses/${this.$route.params.course}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.course = {
          ...classResponse.data,
          avatar: `https://gravatar.com/avatar/${md5(classResponse.data.metadata.id)}?s=200&d=identicon&r=x`
        }
        this.fetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {
        this.setLoading(false)
      }
    }
  },
  mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (id) {
      this.getCourseData(id)
    } else {
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
    // this.getClassData()
  }
}
</script>
